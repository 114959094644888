/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 18, 2024 */



@font-face {
    font-family: 'Barlow';
    src: url('./fonts/barlow-bold-webfont.woff2') format('woff2'),
         url('./fonts/barlow-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: bold;
    font-display: swap;
}

@font-face {
    font-family: 'barlowregular';
    src: url('./fonts/barlow-regular-webfont.woff2') format('woff2'),
         url('./fonts/barlow-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'barlowsemibold';
    src: url('./fonts/barlow-semibold-webfont.woff2') format('woff2'),
         url('./fonts/barlow-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Barlow Condensed';
    src: url('./fonts/barlowcondensed-bold-webfont.woff2') format('woff2'),
         url('./fonts/barlowcondensed-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}